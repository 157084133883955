import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Banded Good Morning 4×8`}</p>
    <p>{`Sumo Deadlift High Pulls 4×4@ approx 80% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`10-1 Deadlifts (225/155)`}</p>
    <p>{`&`}</p>
    <p>{`1-10 Burpee Box Jump Overs (24/20)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      